<template>
    <div class="max-w-sm mx-auto mt-257 px-12 md:px-0">
        <!-- Title -->
        <Title
            :title="$t('newPasswordFormTitle')"
            :subtitle="$t('newPasswordFormSubtitle')"
        />

        <!-- General error message -->
        <p
            v-if="error"
            class="text-red-500 small my-10"
        >
            {{error}}
        </p>

        <!-- New Password form -->
        <form
            @submit.prevent="onSubmit"
            ref="newPasswordForm"
        >
            <!-- New password -->
            <Input
                :class="[errors.password ? 'mb-4' : 'mb-22']"
                :error="errors.password"
                :label="$t('passwordFieldLabel')"
                :placeholder="$t('passwordFieldPlaceholder')"
                type="password"
                name="password"
            />

            <!-- Password confirmation -->
            <Input
                :class="[errors.password_confirmation ? 'mb-8' : 'mb-37']"
                :error="errors.password_confirmation"
                :label="$t('passwordConfirmationFieldLabel')"
                :placeholder="$t('passwordConfiramtionFieldPlaceholder')"
                type="password"
                name="password_confirmation"
            />

            <!-- Submit btn -->
            <Button
                class="button bg-purple-500 text-white hover:bg-purple-600 focus:bg-purple-600 mb-5 font-bold"
                role="submit"
                :text="$t('passwordConfrimationButtonText')"
                :disabled="resetPasswordConfirmLoading"
                :spinning="resetPasswordConfirmLoading"
            />
        </form>
    </div>
</template>

<script>
import Input from '@/components/form/Input'
import Button from '@/components/buttons/Button'
import Title from '@/components/texts/FormTitle'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ResetPasswordChange',

    components: {
        Input,
        Button,
        Title
    },

    data () {
        return {
            resetPasswordConfirmLoading: false
        }
    },

    computed: {
        ...mapGetters(['activationCodeResetPassword', 'smsCodeResetPassword', 'errors', 'error'])
    },

    methods: {
        ...mapActions(['resetPasswordSetPassword']),
        onSubmit () {
            this.resetPasswordConfirmLoading = true

            this.resetPasswordSetPassword({
                reset_password_code: this.activationCodeResetPassword,
                sms_code: this.smsCodeResetPassword,
                password: this.$refs.newPasswordForm.password.value,
                password_confirmation: this.$refs.newPasswordForm.password_confirmation.value
            })
                .then(() => {
                    this.resetPasswordConfirmLoading = false
                })
        }
    }
}
</script>
